/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react');
const { ThemeContextProvider } = require('./src/context/ThemeContext');

exports.onInitialClientRender = () => {
    if (
        'onGatsbyInitialClientRender' in window &&
        typeof window.onGatsbyInitialClientRender === 'function'
    ) {
        window.onGatsbyInitialClientRender();
    }
};

exports.onRouteUpdate = () => {
    if (
        'onGatsbyRouteUpdate' in window &&
        typeof window.onGatsbyRouteUpdate === 'function'
    ) {
        window.onGatsbyRouteUpdate();
    }
};

exports.wrapRootElement = ({ element }) => (
    <ThemeContextProvider>{element}</ThemeContextProvider>
);
