module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-47813171-1","defer":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lauren Clark","short_name":"Lauren","start_url":"/","background_color":"#FFFFFF","theme_color":"#be2328","display":"standalone","icon":"favicon.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ad58e3d97594415a9ab1a3387459f434"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
