import React, { useState } from 'react';

const defaultState = {
    theme: 'main',
    themeToggle: () => {}
};

const ThemeContext = React.createContext(defaultState);

function ThemeContextProvider({ children }) {
    const [theme, setTheme] = useState('main');

    function themeToggle() {
        setTheme((prev) => (prev === 'main' ? 'main-dark' : 'main'));
    }

    return (
        <ThemeContext.Provider value={{ theme, themeToggle }}>
            {children}
        </ThemeContext.Provider>
    );
}

export { ThemeContextProvider, ThemeContext };
